import { default as index2crTMSGZqaMeta } from "/var/www/pecom.kz/www/frontend/pages/[page]/index.vue?macro=true";
import { default as aboutpZXAu3whOoMeta } from "/var/www/pecom.kz/www/frontend/pages/about.vue?macro=true";
import { default as hidden_index2Yz42VqRHzMeta } from "/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue?macro=true";
import { default as errorU7GULSpUfQMeta } from "/var/www/pecom.kz/www/frontend/pages/error.vue?macro=true";
import { default as BlockFaqZgEaGvxaw2Meta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue?macro=true";
import { default as ConsultationBlockAhOpOwUD3sMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue?macro=true";
import { default as ConsultationForminrlSIbhPvMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue?macro=true";
import { default as FilialOpenJbthkKJtXmMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue?macro=true";
import { default as FranchiseBlockchtQvB5k0VMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue?macro=true";
import { default as MapBlockpnBcktLilaMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue?macro=true";
import { default as PalletBlockJunpPFnHmfMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue?macro=true";
import { default as ModalErrord9IXNrJH1UMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue?macro=true";
import { default as ModalSuccesstfGvWHrwRQMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue?macro=true";
import { default as PalletBlockvDXuRvi2imMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue?macro=true";
import { default as PalletOnevuIHzQVgbXMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue?macro=true";
import { default as BlockDescription4O6gG5gFJBMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue?macro=true";
import { default as PartnersBlockgXcoBlBw2cMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue?macro=true";
import { default as PalletBlockpO69Y8tTgdMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue?macro=true";
import { default as PekPlusBlockq2kQB8xQeYMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue?macro=true";
import { default as indexT24sy03xQVMeta } from "/var/www/pecom.kz/www/frontend/pages/franchise/index.vue?macro=true";
import { default as AdvantageBlock5Q4D1KpyuIMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue?macro=true";
import { default as BannerSliderkHYyWpyyp5Meta } from "/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue?macro=true";
import { default as BlockTitleohuhgCmNKEMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue?macro=true";
import { default as ConsultationBlockxPM3ocg98tMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue?macro=true";
import { default as ConsultationFormAOT2AYWlx6Meta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue?macro=true";
import { default as ModalError3i6wRkA14VMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue?macro=true";
import { default as ModalSuccessHAGqFK2hJrMeta } from "/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue?macro=true";
import { default as indexyGq6samUNJMeta } from "/var/www/pecom.kz/www/frontend/pages/index/index.vue?macro=true";
import { default as DefaultAdvantagesSectionWVVd8qK39hMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue?macro=true";
import { default as DefaultHeaderSectionFfUCgkTtKhMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue?macro=true";
import { default as DescriptionSectioneaMEggcSbiMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue?macro=true";
import { default as FaqSectionpEgJPu2vjGMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue?macro=true";
import { default as InfoSectiona0ArwLLEC0Meta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue?macro=true";
import { default as MapSection16dHNm4yQMMeta } from "/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue?macro=true";
import { default as getAddressInfoppFppoVf0LMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js?macro=true";
import { default as getDirectionParamsFromAddressInfopFwuSUxoGWMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js?macro=true";
import { default as getNeedCalculate15CVm2FJ00Meta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js?macro=true";
import { default as getSugggestgRMCr5QOUBMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js?macro=true";
import { default as getTransitCostBlockMessages68JuE3yKeMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js?macro=true";
import { default as isProductCode7CnUWfc1DKMeta } from "/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js?macro=true";
import { default as _91id_934b2N7PaGJZMeta } from "/var/www/pecom.kz/www/frontend/pages/news/[id].vue?macro=true";
import { default as indexYe5dKZsJo1Meta } from "/var/www/pecom.kz/www/frontend/pages/news/index.vue?macro=true";
import { default as CategoryD1AZXK8aTtMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue?macro=true";
import { default as CategoryPalletc3xu70dcmGMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue?macro=true";
import { default as PalletBlockGWpUqlpKfpMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue?macro=true";
import { default as HeadWithSearch7Oy0JowK22Meta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue?macro=true";
import { default as TableBlockL0Zy8p1xryMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue?macro=true";
import { default as TableRow6hMD4YcPkeMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue?macro=true";
import { default as TableSearchcsdeuJ5GaEMeta } from "/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue?macro=true";
import { default as index5dwxxXdK4IMeta } from "/var/www/pecom.kz/www/frontend/pages/package/index.vue?macro=true";
export default [
  {
    name: index2crTMSGZqaMeta?.name ?? "page___kk",
    path: index2crTMSGZqaMeta?.path ?? "/:page()",
    meta: index2crTMSGZqaMeta || {},
    alias: index2crTMSGZqaMeta?.alias || [],
    redirect: index2crTMSGZqaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: index2crTMSGZqaMeta?.name ?? "page___en",
    path: index2crTMSGZqaMeta?.path ?? "/en/:page()",
    meta: index2crTMSGZqaMeta || {},
    alias: index2crTMSGZqaMeta?.alias || [],
    redirect: index2crTMSGZqaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: index2crTMSGZqaMeta?.name ?? "page___ru",
    path: index2crTMSGZqaMeta?.path ?? "/ru/:page()",
    meta: index2crTMSGZqaMeta || {},
    alias: index2crTMSGZqaMeta?.alias || [],
    redirect: index2crTMSGZqaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: aboutpZXAu3whOoMeta?.name ?? "about___kk",
    path: aboutpZXAu3whOoMeta?.path ?? "/about",
    meta: aboutpZXAu3whOoMeta || {},
    alias: aboutpZXAu3whOoMeta?.alias || [],
    redirect: aboutpZXAu3whOoMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutpZXAu3whOoMeta?.name ?? "about___en",
    path: aboutpZXAu3whOoMeta?.path ?? "/en/about",
    meta: aboutpZXAu3whOoMeta || {},
    alias: aboutpZXAu3whOoMeta?.alias || [],
    redirect: aboutpZXAu3whOoMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutpZXAu3whOoMeta?.name ?? "about___ru",
    path: aboutpZXAu3whOoMeta?.path ?? "/ru/about",
    meta: aboutpZXAu3whOoMeta || {},
    alias: aboutpZXAu3whOoMeta?.alias || [],
    redirect: aboutpZXAu3whOoMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: hidden_index2Yz42VqRHzMeta?.name ?? "documents-hidden_index___kk",
    path: hidden_index2Yz42VqRHzMeta?.path ?? "/documents/hidden_index",
    meta: hidden_index2Yz42VqRHzMeta || {},
    alias: hidden_index2Yz42VqRHzMeta?.alias || [],
    redirect: hidden_index2Yz42VqRHzMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue").then(m => m.default || m)
  },
  {
    name: hidden_index2Yz42VqRHzMeta?.name ?? "documents-hidden_index___en",
    path: hidden_index2Yz42VqRHzMeta?.path ?? "/en/documents/hidden_index",
    meta: hidden_index2Yz42VqRHzMeta || {},
    alias: hidden_index2Yz42VqRHzMeta?.alias || [],
    redirect: hidden_index2Yz42VqRHzMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue").then(m => m.default || m)
  },
  {
    name: hidden_index2Yz42VqRHzMeta?.name ?? "documents-hidden_index___ru",
    path: hidden_index2Yz42VqRHzMeta?.path ?? "/ru/documents/hidden_index",
    meta: hidden_index2Yz42VqRHzMeta || {},
    alias: hidden_index2Yz42VqRHzMeta?.alias || [],
    redirect: hidden_index2Yz42VqRHzMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/documents/hidden_index.vue").then(m => m.default || m)
  },
  {
    name: errorU7GULSpUfQMeta?.name ?? "error___kk",
    path: errorU7GULSpUfQMeta?.path ?? "/error",
    meta: errorU7GULSpUfQMeta || {},
    alias: errorU7GULSpUfQMeta?.alias || [],
    redirect: errorU7GULSpUfQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorU7GULSpUfQMeta?.name ?? "error___en",
    path: errorU7GULSpUfQMeta?.path ?? "/en/error",
    meta: errorU7GULSpUfQMeta || {},
    alias: errorU7GULSpUfQMeta?.alias || [],
    redirect: errorU7GULSpUfQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorU7GULSpUfQMeta?.name ?? "error___ru",
    path: errorU7GULSpUfQMeta?.path ?? "/ru/error",
    meta: errorU7GULSpUfQMeta || {},
    alias: errorU7GULSpUfQMeta?.alias || [],
    redirect: errorU7GULSpUfQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: BlockFaqZgEaGvxaw2Meta?.name ?? "franchise-components-BlockFaq___kk",
    path: BlockFaqZgEaGvxaw2Meta?.path ?? "/franchise/components/BlockFaq",
    meta: BlockFaqZgEaGvxaw2Meta || {},
    alias: BlockFaqZgEaGvxaw2Meta?.alias || [],
    redirect: BlockFaqZgEaGvxaw2Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue").then(m => m.default || m)
  },
  {
    name: BlockFaqZgEaGvxaw2Meta?.name ?? "franchise-components-BlockFaq___en",
    path: BlockFaqZgEaGvxaw2Meta?.path ?? "/en/franchise/components/BlockFaq",
    meta: BlockFaqZgEaGvxaw2Meta || {},
    alias: BlockFaqZgEaGvxaw2Meta?.alias || [],
    redirect: BlockFaqZgEaGvxaw2Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue").then(m => m.default || m)
  },
  {
    name: BlockFaqZgEaGvxaw2Meta?.name ?? "franchise-components-BlockFaq___ru",
    path: BlockFaqZgEaGvxaw2Meta?.path ?? "/ru/franchise/components/BlockFaq",
    meta: BlockFaqZgEaGvxaw2Meta || {},
    alias: BlockFaqZgEaGvxaw2Meta?.alias || [],
    redirect: BlockFaqZgEaGvxaw2Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/BlockFaq.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockAhOpOwUD3sMeta?.name ?? "franchise-components-ConsultationBlock___kk",
    path: ConsultationBlockAhOpOwUD3sMeta?.path ?? "/franchise/components/ConsultationBlock",
    meta: ConsultationBlockAhOpOwUD3sMeta || {},
    alias: ConsultationBlockAhOpOwUD3sMeta?.alias || [],
    redirect: ConsultationBlockAhOpOwUD3sMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockAhOpOwUD3sMeta?.name ?? "franchise-components-ConsultationBlock___en",
    path: ConsultationBlockAhOpOwUD3sMeta?.path ?? "/en/franchise/components/ConsultationBlock",
    meta: ConsultationBlockAhOpOwUD3sMeta || {},
    alias: ConsultationBlockAhOpOwUD3sMeta?.alias || [],
    redirect: ConsultationBlockAhOpOwUD3sMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockAhOpOwUD3sMeta?.name ?? "franchise-components-ConsultationBlock___ru",
    path: ConsultationBlockAhOpOwUD3sMeta?.path ?? "/ru/franchise/components/ConsultationBlock",
    meta: ConsultationBlockAhOpOwUD3sMeta || {},
    alias: ConsultationBlockAhOpOwUD3sMeta?.alias || [],
    redirect: ConsultationBlockAhOpOwUD3sMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationForminrlSIbhPvMeta?.name ?? "franchise-components-ConsultationForm___kk",
    path: ConsultationForminrlSIbhPvMeta?.path ?? "/franchise/components/ConsultationForm",
    meta: ConsultationForminrlSIbhPvMeta || {},
    alias: ConsultationForminrlSIbhPvMeta?.alias || [],
    redirect: ConsultationForminrlSIbhPvMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: ConsultationForminrlSIbhPvMeta?.name ?? "franchise-components-ConsultationForm___en",
    path: ConsultationForminrlSIbhPvMeta?.path ?? "/en/franchise/components/ConsultationForm",
    meta: ConsultationForminrlSIbhPvMeta || {},
    alias: ConsultationForminrlSIbhPvMeta?.alias || [],
    redirect: ConsultationForminrlSIbhPvMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: ConsultationForminrlSIbhPvMeta?.name ?? "franchise-components-ConsultationForm___ru",
    path: ConsultationForminrlSIbhPvMeta?.path ?? "/ru/franchise/components/ConsultationForm",
    meta: ConsultationForminrlSIbhPvMeta || {},
    alias: ConsultationForminrlSIbhPvMeta?.alias || [],
    redirect: ConsultationForminrlSIbhPvMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: FilialOpenJbthkKJtXmMeta?.name ?? "franchise-components-FilialOpen___kk",
    path: FilialOpenJbthkKJtXmMeta?.path ?? "/franchise/components/FilialOpen",
    meta: FilialOpenJbthkKJtXmMeta || {},
    alias: FilialOpenJbthkKJtXmMeta?.alias || [],
    redirect: FilialOpenJbthkKJtXmMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue").then(m => m.default || m)
  },
  {
    name: FilialOpenJbthkKJtXmMeta?.name ?? "franchise-components-FilialOpen___en",
    path: FilialOpenJbthkKJtXmMeta?.path ?? "/en/franchise/components/FilialOpen",
    meta: FilialOpenJbthkKJtXmMeta || {},
    alias: FilialOpenJbthkKJtXmMeta?.alias || [],
    redirect: FilialOpenJbthkKJtXmMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue").then(m => m.default || m)
  },
  {
    name: FilialOpenJbthkKJtXmMeta?.name ?? "franchise-components-FilialOpen___ru",
    path: FilialOpenJbthkKJtXmMeta?.path ?? "/ru/franchise/components/FilialOpen",
    meta: FilialOpenJbthkKJtXmMeta || {},
    alias: FilialOpenJbthkKJtXmMeta?.alias || [],
    redirect: FilialOpenJbthkKJtXmMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FilialOpen.vue").then(m => m.default || m)
  },
  {
    name: FranchiseBlockchtQvB5k0VMeta?.name ?? "franchise-components-FranchiseBlock___kk",
    path: FranchiseBlockchtQvB5k0VMeta?.path ?? "/franchise/components/FranchiseBlock",
    meta: FranchiseBlockchtQvB5k0VMeta || {},
    alias: FranchiseBlockchtQvB5k0VMeta?.alias || [],
    redirect: FranchiseBlockchtQvB5k0VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue").then(m => m.default || m)
  },
  {
    name: FranchiseBlockchtQvB5k0VMeta?.name ?? "franchise-components-FranchiseBlock___en",
    path: FranchiseBlockchtQvB5k0VMeta?.path ?? "/en/franchise/components/FranchiseBlock",
    meta: FranchiseBlockchtQvB5k0VMeta || {},
    alias: FranchiseBlockchtQvB5k0VMeta?.alias || [],
    redirect: FranchiseBlockchtQvB5k0VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue").then(m => m.default || m)
  },
  {
    name: FranchiseBlockchtQvB5k0VMeta?.name ?? "franchise-components-FranchiseBlock___ru",
    path: FranchiseBlockchtQvB5k0VMeta?.path ?? "/ru/franchise/components/FranchiseBlock",
    meta: FranchiseBlockchtQvB5k0VMeta || {},
    alias: FranchiseBlockchtQvB5k0VMeta?.alias || [],
    redirect: FranchiseBlockchtQvB5k0VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/FranchiseBlock.vue").then(m => m.default || m)
  },
  {
    name: MapBlockpnBcktLilaMeta?.name ?? "franchise-components-mapBlock-MapBlock___kk",
    path: MapBlockpnBcktLilaMeta?.path ?? "/franchise/components/mapBlock/MapBlock",
    meta: MapBlockpnBcktLilaMeta || {},
    alias: MapBlockpnBcktLilaMeta?.alias || [],
    redirect: MapBlockpnBcktLilaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue").then(m => m.default || m)
  },
  {
    name: MapBlockpnBcktLilaMeta?.name ?? "franchise-components-mapBlock-MapBlock___en",
    path: MapBlockpnBcktLilaMeta?.path ?? "/en/franchise/components/mapBlock/MapBlock",
    meta: MapBlockpnBcktLilaMeta || {},
    alias: MapBlockpnBcktLilaMeta?.alias || [],
    redirect: MapBlockpnBcktLilaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue").then(m => m.default || m)
  },
  {
    name: MapBlockpnBcktLilaMeta?.name ?? "franchise-components-mapBlock-MapBlock___ru",
    path: MapBlockpnBcktLilaMeta?.path ?? "/ru/franchise/components/mapBlock/MapBlock",
    meta: MapBlockpnBcktLilaMeta || {},
    alias: MapBlockpnBcktLilaMeta?.alias || [],
    redirect: MapBlockpnBcktLilaMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/MapBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockJunpPFnHmfMeta?.name ?? "franchise-components-mapBlock-PalletBlock___kk",
    path: PalletBlockJunpPFnHmfMeta?.path ?? "/franchise/components/mapBlock/PalletBlock",
    meta: PalletBlockJunpPFnHmfMeta || {},
    alias: PalletBlockJunpPFnHmfMeta?.alias || [],
    redirect: PalletBlockJunpPFnHmfMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockJunpPFnHmfMeta?.name ?? "franchise-components-mapBlock-PalletBlock___en",
    path: PalletBlockJunpPFnHmfMeta?.path ?? "/en/franchise/components/mapBlock/PalletBlock",
    meta: PalletBlockJunpPFnHmfMeta || {},
    alias: PalletBlockJunpPFnHmfMeta?.alias || [],
    redirect: PalletBlockJunpPFnHmfMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockJunpPFnHmfMeta?.name ?? "franchise-components-mapBlock-PalletBlock___ru",
    path: PalletBlockJunpPFnHmfMeta?.path ?? "/ru/franchise/components/mapBlock/PalletBlock",
    meta: PalletBlockJunpPFnHmfMeta || {},
    alias: PalletBlockJunpPFnHmfMeta?.alias || [],
    redirect: PalletBlockJunpPFnHmfMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/mapBlock/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: ModalErrord9IXNrJH1UMeta?.name ?? "franchise-components-ModalError___kk",
    path: ModalErrord9IXNrJH1UMeta?.path ?? "/franchise/components/ModalError",
    meta: ModalErrord9IXNrJH1UMeta || {},
    alias: ModalErrord9IXNrJH1UMeta?.alias || [],
    redirect: ModalErrord9IXNrJH1UMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalErrord9IXNrJH1UMeta?.name ?? "franchise-components-ModalError___en",
    path: ModalErrord9IXNrJH1UMeta?.path ?? "/en/franchise/components/ModalError",
    meta: ModalErrord9IXNrJH1UMeta || {},
    alias: ModalErrord9IXNrJH1UMeta?.alias || [],
    redirect: ModalErrord9IXNrJH1UMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalErrord9IXNrJH1UMeta?.name ?? "franchise-components-ModalError___ru",
    path: ModalErrord9IXNrJH1UMeta?.path ?? "/ru/franchise/components/ModalError",
    meta: ModalErrord9IXNrJH1UMeta || {},
    alias: ModalErrord9IXNrJH1UMeta?.alias || [],
    redirect: ModalErrord9IXNrJH1UMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccesstfGvWHrwRQMeta?.name ?? "franchise-components-ModalSuccess___kk",
    path: ModalSuccesstfGvWHrwRQMeta?.path ?? "/franchise/components/ModalSuccess",
    meta: ModalSuccesstfGvWHrwRQMeta || {},
    alias: ModalSuccesstfGvWHrwRQMeta?.alias || [],
    redirect: ModalSuccesstfGvWHrwRQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccesstfGvWHrwRQMeta?.name ?? "franchise-components-ModalSuccess___en",
    path: ModalSuccesstfGvWHrwRQMeta?.path ?? "/en/franchise/components/ModalSuccess",
    meta: ModalSuccesstfGvWHrwRQMeta || {},
    alias: ModalSuccesstfGvWHrwRQMeta?.alias || [],
    redirect: ModalSuccesstfGvWHrwRQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccesstfGvWHrwRQMeta?.name ?? "franchise-components-ModalSuccess___ru",
    path: ModalSuccesstfGvWHrwRQMeta?.path ?? "/ru/franchise/components/ModalSuccess",
    meta: ModalSuccesstfGvWHrwRQMeta || {},
    alias: ModalSuccesstfGvWHrwRQMeta?.alias || [],
    redirect: ModalSuccesstfGvWHrwRQMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockvDXuRvi2imMeta?.name ?? "franchise-components-pallet-PalletBlock___kk",
    path: PalletBlockvDXuRvi2imMeta?.path ?? "/franchise/components/pallet/PalletBlock",
    meta: PalletBlockvDXuRvi2imMeta || {},
    alias: PalletBlockvDXuRvi2imMeta?.alias || [],
    redirect: PalletBlockvDXuRvi2imMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockvDXuRvi2imMeta?.name ?? "franchise-components-pallet-PalletBlock___en",
    path: PalletBlockvDXuRvi2imMeta?.path ?? "/en/franchise/components/pallet/PalletBlock",
    meta: PalletBlockvDXuRvi2imMeta || {},
    alias: PalletBlockvDXuRvi2imMeta?.alias || [],
    redirect: PalletBlockvDXuRvi2imMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockvDXuRvi2imMeta?.name ?? "franchise-components-pallet-PalletBlock___ru",
    path: PalletBlockvDXuRvi2imMeta?.path ?? "/ru/franchise/components/pallet/PalletBlock",
    meta: PalletBlockvDXuRvi2imMeta || {},
    alias: PalletBlockvDXuRvi2imMeta?.alias || [],
    redirect: PalletBlockvDXuRvi2imMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletOnevuIHzQVgbXMeta?.name ?? "franchise-components-pallet-PalletOne___kk",
    path: PalletOnevuIHzQVgbXMeta?.path ?? "/franchise/components/pallet/PalletOne",
    meta: PalletOnevuIHzQVgbXMeta || {},
    alias: PalletOnevuIHzQVgbXMeta?.alias || [],
    redirect: PalletOnevuIHzQVgbXMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue").then(m => m.default || m)
  },
  {
    name: PalletOnevuIHzQVgbXMeta?.name ?? "franchise-components-pallet-PalletOne___en",
    path: PalletOnevuIHzQVgbXMeta?.path ?? "/en/franchise/components/pallet/PalletOne",
    meta: PalletOnevuIHzQVgbXMeta || {},
    alias: PalletOnevuIHzQVgbXMeta?.alias || [],
    redirect: PalletOnevuIHzQVgbXMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue").then(m => m.default || m)
  },
  {
    name: PalletOnevuIHzQVgbXMeta?.name ?? "franchise-components-pallet-PalletOne___ru",
    path: PalletOnevuIHzQVgbXMeta?.path ?? "/ru/franchise/components/pallet/PalletOne",
    meta: PalletOnevuIHzQVgbXMeta || {},
    alias: PalletOnevuIHzQVgbXMeta?.alias || [],
    redirect: PalletOnevuIHzQVgbXMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pallet/PalletOne.vue").then(m => m.default || m)
  },
  {
    name: BlockDescription4O6gG5gFJBMeta?.name ?? "franchise-components-partnerBlock-BlockDescription___kk",
    path: BlockDescription4O6gG5gFJBMeta?.path ?? "/franchise/components/partnerBlock/BlockDescription",
    meta: BlockDescription4O6gG5gFJBMeta || {},
    alias: BlockDescription4O6gG5gFJBMeta?.alias || [],
    redirect: BlockDescription4O6gG5gFJBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue").then(m => m.default || m)
  },
  {
    name: BlockDescription4O6gG5gFJBMeta?.name ?? "franchise-components-partnerBlock-BlockDescription___en",
    path: BlockDescription4O6gG5gFJBMeta?.path ?? "/en/franchise/components/partnerBlock/BlockDescription",
    meta: BlockDescription4O6gG5gFJBMeta || {},
    alias: BlockDescription4O6gG5gFJBMeta?.alias || [],
    redirect: BlockDescription4O6gG5gFJBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue").then(m => m.default || m)
  },
  {
    name: BlockDescription4O6gG5gFJBMeta?.name ?? "franchise-components-partnerBlock-BlockDescription___ru",
    path: BlockDescription4O6gG5gFJBMeta?.path ?? "/ru/franchise/components/partnerBlock/BlockDescription",
    meta: BlockDescription4O6gG5gFJBMeta || {},
    alias: BlockDescription4O6gG5gFJBMeta?.alias || [],
    redirect: BlockDescription4O6gG5gFJBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/BlockDescription.vue").then(m => m.default || m)
  },
  {
    name: PartnersBlockgXcoBlBw2cMeta?.name ?? "franchise-components-partnerBlock-PartnersBlock___kk",
    path: PartnersBlockgXcoBlBw2cMeta?.path ?? "/franchise/components/partnerBlock/PartnersBlock",
    meta: PartnersBlockgXcoBlBw2cMeta || {},
    alias: PartnersBlockgXcoBlBw2cMeta?.alias || [],
    redirect: PartnersBlockgXcoBlBw2cMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue").then(m => m.default || m)
  },
  {
    name: PartnersBlockgXcoBlBw2cMeta?.name ?? "franchise-components-partnerBlock-PartnersBlock___en",
    path: PartnersBlockgXcoBlBw2cMeta?.path ?? "/en/franchise/components/partnerBlock/PartnersBlock",
    meta: PartnersBlockgXcoBlBw2cMeta || {},
    alias: PartnersBlockgXcoBlBw2cMeta?.alias || [],
    redirect: PartnersBlockgXcoBlBw2cMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue").then(m => m.default || m)
  },
  {
    name: PartnersBlockgXcoBlBw2cMeta?.name ?? "franchise-components-partnerBlock-PartnersBlock___ru",
    path: PartnersBlockgXcoBlBw2cMeta?.path ?? "/ru/franchise/components/partnerBlock/PartnersBlock",
    meta: PartnersBlockgXcoBlBw2cMeta || {},
    alias: PartnersBlockgXcoBlBw2cMeta?.alias || [],
    redirect: PartnersBlockgXcoBlBw2cMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/partnerBlock/PartnersBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockpO69Y8tTgdMeta?.name ?? "franchise-components-pekPlus-PalletBlock___kk",
    path: PalletBlockpO69Y8tTgdMeta?.path ?? "/franchise/components/pekPlus/PalletBlock",
    meta: PalletBlockpO69Y8tTgdMeta || {},
    alias: PalletBlockpO69Y8tTgdMeta?.alias || [],
    redirect: PalletBlockpO69Y8tTgdMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockpO69Y8tTgdMeta?.name ?? "franchise-components-pekPlus-PalletBlock___en",
    path: PalletBlockpO69Y8tTgdMeta?.path ?? "/en/franchise/components/pekPlus/PalletBlock",
    meta: PalletBlockpO69Y8tTgdMeta || {},
    alias: PalletBlockpO69Y8tTgdMeta?.alias || [],
    redirect: PalletBlockpO69Y8tTgdMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockpO69Y8tTgdMeta?.name ?? "franchise-components-pekPlus-PalletBlock___ru",
    path: PalletBlockpO69Y8tTgdMeta?.path ?? "/ru/franchise/components/pekPlus/PalletBlock",
    meta: PalletBlockpO69Y8tTgdMeta || {},
    alias: PalletBlockpO69Y8tTgdMeta?.alias || [],
    redirect: PalletBlockpO69Y8tTgdMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PekPlusBlockq2kQB8xQeYMeta?.name ?? "franchise-components-pekPlus-PekPlusBlock___kk",
    path: PekPlusBlockq2kQB8xQeYMeta?.path ?? "/franchise/components/pekPlus/PekPlusBlock",
    meta: PekPlusBlockq2kQB8xQeYMeta || {},
    alias: PekPlusBlockq2kQB8xQeYMeta?.alias || [],
    redirect: PekPlusBlockq2kQB8xQeYMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue").then(m => m.default || m)
  },
  {
    name: PekPlusBlockq2kQB8xQeYMeta?.name ?? "franchise-components-pekPlus-PekPlusBlock___en",
    path: PekPlusBlockq2kQB8xQeYMeta?.path ?? "/en/franchise/components/pekPlus/PekPlusBlock",
    meta: PekPlusBlockq2kQB8xQeYMeta || {},
    alias: PekPlusBlockq2kQB8xQeYMeta?.alias || [],
    redirect: PekPlusBlockq2kQB8xQeYMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue").then(m => m.default || m)
  },
  {
    name: PekPlusBlockq2kQB8xQeYMeta?.name ?? "franchise-components-pekPlus-PekPlusBlock___ru",
    path: PekPlusBlockq2kQB8xQeYMeta?.path ?? "/ru/franchise/components/pekPlus/PekPlusBlock",
    meta: PekPlusBlockq2kQB8xQeYMeta || {},
    alias: PekPlusBlockq2kQB8xQeYMeta?.alias || [],
    redirect: PekPlusBlockq2kQB8xQeYMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/components/pekPlus/PekPlusBlock.vue").then(m => m.default || m)
  },
  {
    name: indexT24sy03xQVMeta?.name ?? "franchise___kk",
    path: indexT24sy03xQVMeta?.path ?? "/franchise",
    meta: indexT24sy03xQVMeta || {},
    alias: indexT24sy03xQVMeta?.alias || [],
    redirect: indexT24sy03xQVMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue").then(m => m.default || m)
  },
  {
    name: indexT24sy03xQVMeta?.name ?? "franchise___en",
    path: indexT24sy03xQVMeta?.path ?? "/en/franchise",
    meta: indexT24sy03xQVMeta || {},
    alias: indexT24sy03xQVMeta?.alias || [],
    redirect: indexT24sy03xQVMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue").then(m => m.default || m)
  },
  {
    name: indexT24sy03xQVMeta?.name ?? "franchise___ru",
    path: indexT24sy03xQVMeta?.path ?? "/ru/franchise",
    meta: indexT24sy03xQVMeta || {},
    alias: indexT24sy03xQVMeta?.alias || [],
    redirect: indexT24sy03xQVMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/franchise/index.vue").then(m => m.default || m)
  },
  {
    name: AdvantageBlock5Q4D1KpyuIMeta?.name ?? "index-components-AdvantageBlock___kk",
    path: AdvantageBlock5Q4D1KpyuIMeta?.path ?? "//components/AdvantageBlock",
    meta: AdvantageBlock5Q4D1KpyuIMeta || {},
    alias: AdvantageBlock5Q4D1KpyuIMeta?.alias || [],
    redirect: AdvantageBlock5Q4D1KpyuIMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue").then(m => m.default || m)
  },
  {
    name: AdvantageBlock5Q4D1KpyuIMeta?.name ?? "index-components-AdvantageBlock___en",
    path: AdvantageBlock5Q4D1KpyuIMeta?.path ?? "/en//components/AdvantageBlock",
    meta: AdvantageBlock5Q4D1KpyuIMeta || {},
    alias: AdvantageBlock5Q4D1KpyuIMeta?.alias || [],
    redirect: AdvantageBlock5Q4D1KpyuIMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue").then(m => m.default || m)
  },
  {
    name: AdvantageBlock5Q4D1KpyuIMeta?.name ?? "index-components-AdvantageBlock___ru",
    path: AdvantageBlock5Q4D1KpyuIMeta?.path ?? "/ru//components/AdvantageBlock",
    meta: AdvantageBlock5Q4D1KpyuIMeta || {},
    alias: AdvantageBlock5Q4D1KpyuIMeta?.alias || [],
    redirect: AdvantageBlock5Q4D1KpyuIMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/AdvantageBlock.vue").then(m => m.default || m)
  },
  {
    name: BannerSliderkHYyWpyyp5Meta?.name ?? "index-components-BannerSlider___kk",
    path: BannerSliderkHYyWpyyp5Meta?.path ?? "//components/BannerSlider",
    meta: BannerSliderkHYyWpyyp5Meta || {},
    alias: BannerSliderkHYyWpyyp5Meta?.alias || [],
    redirect: BannerSliderkHYyWpyyp5Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue").then(m => m.default || m)
  },
  {
    name: BannerSliderkHYyWpyyp5Meta?.name ?? "index-components-BannerSlider___en",
    path: BannerSliderkHYyWpyyp5Meta?.path ?? "/en//components/BannerSlider",
    meta: BannerSliderkHYyWpyyp5Meta || {},
    alias: BannerSliderkHYyWpyyp5Meta?.alias || [],
    redirect: BannerSliderkHYyWpyyp5Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue").then(m => m.default || m)
  },
  {
    name: BannerSliderkHYyWpyyp5Meta?.name ?? "index-components-BannerSlider___ru",
    path: BannerSliderkHYyWpyyp5Meta?.path ?? "/ru//components/BannerSlider",
    meta: BannerSliderkHYyWpyyp5Meta || {},
    alias: BannerSliderkHYyWpyyp5Meta?.alias || [],
    redirect: BannerSliderkHYyWpyyp5Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BannerSlider.vue").then(m => m.default || m)
  },
  {
    name: BlockTitleohuhgCmNKEMeta?.name ?? "index-components-BlockTitle___kk",
    path: BlockTitleohuhgCmNKEMeta?.path ?? "//components/BlockTitle",
    meta: BlockTitleohuhgCmNKEMeta || {},
    alias: BlockTitleohuhgCmNKEMeta?.alias || [],
    redirect: BlockTitleohuhgCmNKEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue").then(m => m.default || m)
  },
  {
    name: BlockTitleohuhgCmNKEMeta?.name ?? "index-components-BlockTitle___en",
    path: BlockTitleohuhgCmNKEMeta?.path ?? "/en//components/BlockTitle",
    meta: BlockTitleohuhgCmNKEMeta || {},
    alias: BlockTitleohuhgCmNKEMeta?.alias || [],
    redirect: BlockTitleohuhgCmNKEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue").then(m => m.default || m)
  },
  {
    name: BlockTitleohuhgCmNKEMeta?.name ?? "index-components-BlockTitle___ru",
    path: BlockTitleohuhgCmNKEMeta?.path ?? "/ru//components/BlockTitle",
    meta: BlockTitleohuhgCmNKEMeta || {},
    alias: BlockTitleohuhgCmNKEMeta?.alias || [],
    redirect: BlockTitleohuhgCmNKEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/BlockTitle.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockxPM3ocg98tMeta?.name ?? "index-components-ConsultationBlock___kk",
    path: ConsultationBlockxPM3ocg98tMeta?.path ?? "//components/ConsultationBlock",
    meta: ConsultationBlockxPM3ocg98tMeta || {},
    alias: ConsultationBlockxPM3ocg98tMeta?.alias || [],
    redirect: ConsultationBlockxPM3ocg98tMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockxPM3ocg98tMeta?.name ?? "index-components-ConsultationBlock___en",
    path: ConsultationBlockxPM3ocg98tMeta?.path ?? "/en//components/ConsultationBlock",
    meta: ConsultationBlockxPM3ocg98tMeta || {},
    alias: ConsultationBlockxPM3ocg98tMeta?.alias || [],
    redirect: ConsultationBlockxPM3ocg98tMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationBlockxPM3ocg98tMeta?.name ?? "index-components-ConsultationBlock___ru",
    path: ConsultationBlockxPM3ocg98tMeta?.path ?? "/ru//components/ConsultationBlock",
    meta: ConsultationBlockxPM3ocg98tMeta || {},
    alias: ConsultationBlockxPM3ocg98tMeta?.alias || [],
    redirect: ConsultationBlockxPM3ocg98tMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationBlock.vue").then(m => m.default || m)
  },
  {
    name: ConsultationFormAOT2AYWlx6Meta?.name ?? "index-components-ConsultationForm___kk",
    path: ConsultationFormAOT2AYWlx6Meta?.path ?? "//components/ConsultationForm",
    meta: ConsultationFormAOT2AYWlx6Meta || {},
    alias: ConsultationFormAOT2AYWlx6Meta?.alias || [],
    redirect: ConsultationFormAOT2AYWlx6Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: ConsultationFormAOT2AYWlx6Meta?.name ?? "index-components-ConsultationForm___en",
    path: ConsultationFormAOT2AYWlx6Meta?.path ?? "/en//components/ConsultationForm",
    meta: ConsultationFormAOT2AYWlx6Meta || {},
    alias: ConsultationFormAOT2AYWlx6Meta?.alias || [],
    redirect: ConsultationFormAOT2AYWlx6Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: ConsultationFormAOT2AYWlx6Meta?.name ?? "index-components-ConsultationForm___ru",
    path: ConsultationFormAOT2AYWlx6Meta?.path ?? "/ru//components/ConsultationForm",
    meta: ConsultationFormAOT2AYWlx6Meta || {},
    alias: ConsultationFormAOT2AYWlx6Meta?.alias || [],
    redirect: ConsultationFormAOT2AYWlx6Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ConsultationForm.vue").then(m => m.default || m)
  },
  {
    name: ModalError3i6wRkA14VMeta?.name ?? "index-components-ModalError___kk",
    path: ModalError3i6wRkA14VMeta?.path ?? "//components/ModalError",
    meta: ModalError3i6wRkA14VMeta || {},
    alias: ModalError3i6wRkA14VMeta?.alias || [],
    redirect: ModalError3i6wRkA14VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalError3i6wRkA14VMeta?.name ?? "index-components-ModalError___en",
    path: ModalError3i6wRkA14VMeta?.path ?? "/en//components/ModalError",
    meta: ModalError3i6wRkA14VMeta || {},
    alias: ModalError3i6wRkA14VMeta?.alias || [],
    redirect: ModalError3i6wRkA14VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalError3i6wRkA14VMeta?.name ?? "index-components-ModalError___ru",
    path: ModalError3i6wRkA14VMeta?.path ?? "/ru//components/ModalError",
    meta: ModalError3i6wRkA14VMeta || {},
    alias: ModalError3i6wRkA14VMeta?.alias || [],
    redirect: ModalError3i6wRkA14VMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalError.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccessHAGqFK2hJrMeta?.name ?? "index-components-ModalSuccess___kk",
    path: ModalSuccessHAGqFK2hJrMeta?.path ?? "//components/ModalSuccess",
    meta: ModalSuccessHAGqFK2hJrMeta || {},
    alias: ModalSuccessHAGqFK2hJrMeta?.alias || [],
    redirect: ModalSuccessHAGqFK2hJrMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccessHAGqFK2hJrMeta?.name ?? "index-components-ModalSuccess___en",
    path: ModalSuccessHAGqFK2hJrMeta?.path ?? "/en//components/ModalSuccess",
    meta: ModalSuccessHAGqFK2hJrMeta || {},
    alias: ModalSuccessHAGqFK2hJrMeta?.alias || [],
    redirect: ModalSuccessHAGqFK2hJrMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: ModalSuccessHAGqFK2hJrMeta?.name ?? "index-components-ModalSuccess___ru",
    path: ModalSuccessHAGqFK2hJrMeta?.path ?? "/ru//components/ModalSuccess",
    meta: ModalSuccessHAGqFK2hJrMeta || {},
    alias: ModalSuccessHAGqFK2hJrMeta?.alias || [],
    redirect: ModalSuccessHAGqFK2hJrMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/components/ModalSuccess.vue").then(m => m.default || m)
  },
  {
    name: indexyGq6samUNJMeta?.name ?? "index___kk",
    path: indexyGq6samUNJMeta?.path ?? "/",
    meta: indexyGq6samUNJMeta || {},
    alias: indexyGq6samUNJMeta?.alias || [],
    redirect: indexyGq6samUNJMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGq6samUNJMeta?.name ?? "index___en",
    path: indexyGq6samUNJMeta?.path ?? "/en",
    meta: indexyGq6samUNJMeta || {},
    alias: indexyGq6samUNJMeta?.alias || [],
    redirect: indexyGq6samUNJMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGq6samUNJMeta?.name ?? "index___ru",
    path: indexyGq6samUNJMeta?.path ?? "/ru",
    meta: indexyGq6samUNJMeta || {},
    alias: indexyGq6samUNJMeta?.alias || [],
    redirect: indexyGq6samUNJMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: DefaultAdvantagesSectionWVVd8qK39hMeta?.name ?? "index-sections-DefaultAdvantagesSection___kk",
    path: DefaultAdvantagesSectionWVVd8qK39hMeta?.path ?? "//sections/DefaultAdvantagesSection",
    meta: DefaultAdvantagesSectionWVVd8qK39hMeta || {},
    alias: DefaultAdvantagesSectionWVVd8qK39hMeta?.alias || [],
    redirect: DefaultAdvantagesSectionWVVd8qK39hMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue").then(m => m.default || m)
  },
  {
    name: DefaultAdvantagesSectionWVVd8qK39hMeta?.name ?? "index-sections-DefaultAdvantagesSection___en",
    path: DefaultAdvantagesSectionWVVd8qK39hMeta?.path ?? "/en//sections/DefaultAdvantagesSection",
    meta: DefaultAdvantagesSectionWVVd8qK39hMeta || {},
    alias: DefaultAdvantagesSectionWVVd8qK39hMeta?.alias || [],
    redirect: DefaultAdvantagesSectionWVVd8qK39hMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue").then(m => m.default || m)
  },
  {
    name: DefaultAdvantagesSectionWVVd8qK39hMeta?.name ?? "index-sections-DefaultAdvantagesSection___ru",
    path: DefaultAdvantagesSectionWVVd8qK39hMeta?.path ?? "/ru//sections/DefaultAdvantagesSection",
    meta: DefaultAdvantagesSectionWVVd8qK39hMeta || {},
    alias: DefaultAdvantagesSectionWVVd8qK39hMeta?.alias || [],
    redirect: DefaultAdvantagesSectionWVVd8qK39hMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultAdvantagesSection.vue").then(m => m.default || m)
  },
  {
    name: DefaultHeaderSectionFfUCgkTtKhMeta?.name ?? "index-sections-DefaultHeaderSection___kk",
    path: DefaultHeaderSectionFfUCgkTtKhMeta?.path ?? "//sections/DefaultHeaderSection",
    meta: DefaultHeaderSectionFfUCgkTtKhMeta || {},
    alias: DefaultHeaderSectionFfUCgkTtKhMeta?.alias || [],
    redirect: DefaultHeaderSectionFfUCgkTtKhMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue").then(m => m.default || m)
  },
  {
    name: DefaultHeaderSectionFfUCgkTtKhMeta?.name ?? "index-sections-DefaultHeaderSection___en",
    path: DefaultHeaderSectionFfUCgkTtKhMeta?.path ?? "/en//sections/DefaultHeaderSection",
    meta: DefaultHeaderSectionFfUCgkTtKhMeta || {},
    alias: DefaultHeaderSectionFfUCgkTtKhMeta?.alias || [],
    redirect: DefaultHeaderSectionFfUCgkTtKhMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue").then(m => m.default || m)
  },
  {
    name: DefaultHeaderSectionFfUCgkTtKhMeta?.name ?? "index-sections-DefaultHeaderSection___ru",
    path: DefaultHeaderSectionFfUCgkTtKhMeta?.path ?? "/ru//sections/DefaultHeaderSection",
    meta: DefaultHeaderSectionFfUCgkTtKhMeta || {},
    alias: DefaultHeaderSectionFfUCgkTtKhMeta?.alias || [],
    redirect: DefaultHeaderSectionFfUCgkTtKhMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DefaultHeaderSection.vue").then(m => m.default || m)
  },
  {
    name: DescriptionSectioneaMEggcSbiMeta?.name ?? "index-sections-DescriptionSection___kk",
    path: DescriptionSectioneaMEggcSbiMeta?.path ?? "//sections/DescriptionSection",
    meta: DescriptionSectioneaMEggcSbiMeta || {},
    alias: DescriptionSectioneaMEggcSbiMeta?.alias || [],
    redirect: DescriptionSectioneaMEggcSbiMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: DescriptionSectioneaMEggcSbiMeta?.name ?? "index-sections-DescriptionSection___en",
    path: DescriptionSectioneaMEggcSbiMeta?.path ?? "/en//sections/DescriptionSection",
    meta: DescriptionSectioneaMEggcSbiMeta || {},
    alias: DescriptionSectioneaMEggcSbiMeta?.alias || [],
    redirect: DescriptionSectioneaMEggcSbiMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: DescriptionSectioneaMEggcSbiMeta?.name ?? "index-sections-DescriptionSection___ru",
    path: DescriptionSectioneaMEggcSbiMeta?.path ?? "/ru//sections/DescriptionSection",
    meta: DescriptionSectioneaMEggcSbiMeta || {},
    alias: DescriptionSectioneaMEggcSbiMeta?.alias || [],
    redirect: DescriptionSectioneaMEggcSbiMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: FaqSectionpEgJPu2vjGMeta?.name ?? "index-sections-FaqSection___kk",
    path: FaqSectionpEgJPu2vjGMeta?.path ?? "//sections/FaqSection",
    meta: FaqSectionpEgJPu2vjGMeta || {},
    alias: FaqSectionpEgJPu2vjGMeta?.alias || [],
    redirect: FaqSectionpEgJPu2vjGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue").then(m => m.default || m)
  },
  {
    name: FaqSectionpEgJPu2vjGMeta?.name ?? "index-sections-FaqSection___en",
    path: FaqSectionpEgJPu2vjGMeta?.path ?? "/en//sections/FaqSection",
    meta: FaqSectionpEgJPu2vjGMeta || {},
    alias: FaqSectionpEgJPu2vjGMeta?.alias || [],
    redirect: FaqSectionpEgJPu2vjGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue").then(m => m.default || m)
  },
  {
    name: FaqSectionpEgJPu2vjGMeta?.name ?? "index-sections-FaqSection___ru",
    path: FaqSectionpEgJPu2vjGMeta?.path ?? "/ru//sections/FaqSection",
    meta: FaqSectionpEgJPu2vjGMeta || {},
    alias: FaqSectionpEgJPu2vjGMeta?.alias || [],
    redirect: FaqSectionpEgJPu2vjGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/FaqSection.vue").then(m => m.default || m)
  },
  {
    name: InfoSectiona0ArwLLEC0Meta?.name ?? "index-sections-InfoSection___kk",
    path: InfoSectiona0ArwLLEC0Meta?.path ?? "//sections/InfoSection",
    meta: InfoSectiona0ArwLLEC0Meta || {},
    alias: InfoSectiona0ArwLLEC0Meta?.alias || [],
    redirect: InfoSectiona0ArwLLEC0Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue").then(m => m.default || m)
  },
  {
    name: InfoSectiona0ArwLLEC0Meta?.name ?? "index-sections-InfoSection___en",
    path: InfoSectiona0ArwLLEC0Meta?.path ?? "/en//sections/InfoSection",
    meta: InfoSectiona0ArwLLEC0Meta || {},
    alias: InfoSectiona0ArwLLEC0Meta?.alias || [],
    redirect: InfoSectiona0ArwLLEC0Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue").then(m => m.default || m)
  },
  {
    name: InfoSectiona0ArwLLEC0Meta?.name ?? "index-sections-InfoSection___ru",
    path: InfoSectiona0ArwLLEC0Meta?.path ?? "/ru//sections/InfoSection",
    meta: InfoSectiona0ArwLLEC0Meta || {},
    alias: InfoSectiona0ArwLLEC0Meta?.alias || [],
    redirect: InfoSectiona0ArwLLEC0Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/InfoSection.vue").then(m => m.default || m)
  },
  {
    name: MapSection16dHNm4yQMMeta?.name ?? "index-sections-MapSection___kk",
    path: MapSection16dHNm4yQMMeta?.path ?? "//sections/MapSection",
    meta: MapSection16dHNm4yQMMeta || {},
    alias: MapSection16dHNm4yQMMeta?.alias || [],
    redirect: MapSection16dHNm4yQMMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue").then(m => m.default || m)
  },
  {
    name: MapSection16dHNm4yQMMeta?.name ?? "index-sections-MapSection___en",
    path: MapSection16dHNm4yQMMeta?.path ?? "/en//sections/MapSection",
    meta: MapSection16dHNm4yQMMeta || {},
    alias: MapSection16dHNm4yQMMeta?.alias || [],
    redirect: MapSection16dHNm4yQMMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue").then(m => m.default || m)
  },
  {
    name: MapSection16dHNm4yQMMeta?.name ?? "index-sections-MapSection___ru",
    path: MapSection16dHNm4yQMMeta?.path ?? "/ru//sections/MapSection",
    meta: MapSection16dHNm4yQMMeta || {},
    alias: MapSection16dHNm4yQMMeta?.alias || [],
    redirect: MapSection16dHNm4yQMMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/sections/MapSection.vue").then(m => m.default || m)
  },
  {
    name: getAddressInfoppFppoVf0LMeta?.name ?? "index-utils-getAddressInfo___kk",
    path: getAddressInfoppFppoVf0LMeta?.path ?? "//utils/getAddressInfo",
    meta: getAddressInfoppFppoVf0LMeta || {},
    alias: getAddressInfoppFppoVf0LMeta?.alias || [],
    redirect: getAddressInfoppFppoVf0LMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getAddressInfoppFppoVf0LMeta?.name ?? "index-utils-getAddressInfo___en",
    path: getAddressInfoppFppoVf0LMeta?.path ?? "/en//utils/getAddressInfo",
    meta: getAddressInfoppFppoVf0LMeta || {},
    alias: getAddressInfoppFppoVf0LMeta?.alias || [],
    redirect: getAddressInfoppFppoVf0LMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getAddressInfoppFppoVf0LMeta?.name ?? "index-utils-getAddressInfo___ru",
    path: getAddressInfoppFppoVf0LMeta?.path ?? "/ru//utils/getAddressInfo",
    meta: getAddressInfoppFppoVf0LMeta || {},
    alias: getAddressInfoppFppoVf0LMeta?.alias || [],
    redirect: getAddressInfoppFppoVf0LMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.name ?? "index-utils-getDirectionParamsFromAddressInfo___kk",
    path: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.path ?? "//utils/getDirectionParamsFromAddressInfo",
    meta: getDirectionParamsFromAddressInfopFwuSUxoGWMeta || {},
    alias: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.alias || [],
    redirect: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.name ?? "index-utils-getDirectionParamsFromAddressInfo___en",
    path: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.path ?? "/en//utils/getDirectionParamsFromAddressInfo",
    meta: getDirectionParamsFromAddressInfopFwuSUxoGWMeta || {},
    alias: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.alias || [],
    redirect: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.name ?? "index-utils-getDirectionParamsFromAddressInfo___ru",
    path: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.path ?? "/ru//utils/getDirectionParamsFromAddressInfo",
    meta: getDirectionParamsFromAddressInfopFwuSUxoGWMeta || {},
    alias: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.alias || [],
    redirect: getDirectionParamsFromAddressInfopFwuSUxoGWMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getDirectionParamsFromAddressInfo.js").then(m => m.default || m)
  },
  {
    name: getNeedCalculate15CVm2FJ00Meta?.name ?? "index-utils-getNeedCalculate___kk",
    path: getNeedCalculate15CVm2FJ00Meta?.path ?? "//utils/getNeedCalculate",
    meta: getNeedCalculate15CVm2FJ00Meta || {},
    alias: getNeedCalculate15CVm2FJ00Meta?.alias || [],
    redirect: getNeedCalculate15CVm2FJ00Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js").then(m => m.default || m)
  },
  {
    name: getNeedCalculate15CVm2FJ00Meta?.name ?? "index-utils-getNeedCalculate___en",
    path: getNeedCalculate15CVm2FJ00Meta?.path ?? "/en//utils/getNeedCalculate",
    meta: getNeedCalculate15CVm2FJ00Meta || {},
    alias: getNeedCalculate15CVm2FJ00Meta?.alias || [],
    redirect: getNeedCalculate15CVm2FJ00Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js").then(m => m.default || m)
  },
  {
    name: getNeedCalculate15CVm2FJ00Meta?.name ?? "index-utils-getNeedCalculate___ru",
    path: getNeedCalculate15CVm2FJ00Meta?.path ?? "/ru//utils/getNeedCalculate",
    meta: getNeedCalculate15CVm2FJ00Meta || {},
    alias: getNeedCalculate15CVm2FJ00Meta?.alias || [],
    redirect: getNeedCalculate15CVm2FJ00Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getNeedCalculate.js").then(m => m.default || m)
  },
  {
    name: getSugggestgRMCr5QOUBMeta?.name ?? "index-utils-getSugggest___kk",
    path: getSugggestgRMCr5QOUBMeta?.path ?? "//utils/getSugggest",
    meta: getSugggestgRMCr5QOUBMeta || {},
    alias: getSugggestgRMCr5QOUBMeta?.alias || [],
    redirect: getSugggestgRMCr5QOUBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js").then(m => m.default || m)
  },
  {
    name: getSugggestgRMCr5QOUBMeta?.name ?? "index-utils-getSugggest___en",
    path: getSugggestgRMCr5QOUBMeta?.path ?? "/en//utils/getSugggest",
    meta: getSugggestgRMCr5QOUBMeta || {},
    alias: getSugggestgRMCr5QOUBMeta?.alias || [],
    redirect: getSugggestgRMCr5QOUBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js").then(m => m.default || m)
  },
  {
    name: getSugggestgRMCr5QOUBMeta?.name ?? "index-utils-getSugggest___ru",
    path: getSugggestgRMCr5QOUBMeta?.path ?? "/ru//utils/getSugggest",
    meta: getSugggestgRMCr5QOUBMeta || {},
    alias: getSugggestgRMCr5QOUBMeta?.alias || [],
    redirect: getSugggestgRMCr5QOUBMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getSugggest.js").then(m => m.default || m)
  },
  {
    name: getTransitCostBlockMessages68JuE3yKeMeta?.name ?? "index-utils-getTransitCostBlockMessage___kk",
    path: getTransitCostBlockMessages68JuE3yKeMeta?.path ?? "//utils/getTransitCostBlockMessage",
    meta: getTransitCostBlockMessages68JuE3yKeMeta || {},
    alias: getTransitCostBlockMessages68JuE3yKeMeta?.alias || [],
    redirect: getTransitCostBlockMessages68JuE3yKeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js").then(m => m.default || m)
  },
  {
    name: getTransitCostBlockMessages68JuE3yKeMeta?.name ?? "index-utils-getTransitCostBlockMessage___en",
    path: getTransitCostBlockMessages68JuE3yKeMeta?.path ?? "/en//utils/getTransitCostBlockMessage",
    meta: getTransitCostBlockMessages68JuE3yKeMeta || {},
    alias: getTransitCostBlockMessages68JuE3yKeMeta?.alias || [],
    redirect: getTransitCostBlockMessages68JuE3yKeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js").then(m => m.default || m)
  },
  {
    name: getTransitCostBlockMessages68JuE3yKeMeta?.name ?? "index-utils-getTransitCostBlockMessage___ru",
    path: getTransitCostBlockMessages68JuE3yKeMeta?.path ?? "/ru//utils/getTransitCostBlockMessage",
    meta: getTransitCostBlockMessages68JuE3yKeMeta || {},
    alias: getTransitCostBlockMessages68JuE3yKeMeta?.alias || [],
    redirect: getTransitCostBlockMessages68JuE3yKeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/getTransitCostBlockMessage.js").then(m => m.default || m)
  },
  {
    name: isProductCode7CnUWfc1DKMeta?.name ?? "index-utils-isProductCode___kk",
    path: isProductCode7CnUWfc1DKMeta?.path ?? "//utils/isProductCode",
    meta: isProductCode7CnUWfc1DKMeta || {},
    alias: isProductCode7CnUWfc1DKMeta?.alias || [],
    redirect: isProductCode7CnUWfc1DKMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js").then(m => m.default || m)
  },
  {
    name: isProductCode7CnUWfc1DKMeta?.name ?? "index-utils-isProductCode___en",
    path: isProductCode7CnUWfc1DKMeta?.path ?? "/en//utils/isProductCode",
    meta: isProductCode7CnUWfc1DKMeta || {},
    alias: isProductCode7CnUWfc1DKMeta?.alias || [],
    redirect: isProductCode7CnUWfc1DKMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js").then(m => m.default || m)
  },
  {
    name: isProductCode7CnUWfc1DKMeta?.name ?? "index-utils-isProductCode___ru",
    path: isProductCode7CnUWfc1DKMeta?.path ?? "/ru//utils/isProductCode",
    meta: isProductCode7CnUWfc1DKMeta || {},
    alias: isProductCode7CnUWfc1DKMeta?.alias || [],
    redirect: isProductCode7CnUWfc1DKMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/index/utils/isProductCode.js").then(m => m.default || m)
  },
  {
    name: _91id_934b2N7PaGJZMeta?.name ?? "news-id___kk",
    path: _91id_934b2N7PaGJZMeta?.path ?? "/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    alias: _91id_934b2N7PaGJZMeta?.alias || [],
    redirect: _91id_934b2N7PaGJZMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934b2N7PaGJZMeta?.name ?? "news-id___en",
    path: _91id_934b2N7PaGJZMeta?.path ?? "/en/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    alias: _91id_934b2N7PaGJZMeta?.alias || [],
    redirect: _91id_934b2N7PaGJZMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_934b2N7PaGJZMeta?.name ?? "news-id___ru",
    path: _91id_934b2N7PaGJZMeta?.path ?? "/ru/news/:id()",
    meta: _91id_934b2N7PaGJZMeta || {},
    alias: _91id_934b2N7PaGJZMeta?.alias || [],
    redirect: _91id_934b2N7PaGJZMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYe5dKZsJo1Meta?.name ?? "news___kk",
    path: indexYe5dKZsJo1Meta?.path ?? "/news",
    meta: indexYe5dKZsJo1Meta || {},
    alias: indexYe5dKZsJo1Meta?.alias || [],
    redirect: indexYe5dKZsJo1Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYe5dKZsJo1Meta?.name ?? "news___en",
    path: indexYe5dKZsJo1Meta?.path ?? "/en/news",
    meta: indexYe5dKZsJo1Meta || {},
    alias: indexYe5dKZsJo1Meta?.alias || [],
    redirect: indexYe5dKZsJo1Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYe5dKZsJo1Meta?.name ?? "news___ru",
    path: indexYe5dKZsJo1Meta?.path ?? "/ru/news",
    meta: indexYe5dKZsJo1Meta || {},
    alias: indexYe5dKZsJo1Meta?.alias || [],
    redirect: indexYe5dKZsJo1Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: CategoryD1AZXK8aTtMeta?.name ?? "package-components-Category___kk",
    path: CategoryD1AZXK8aTtMeta?.path ?? "/package/components/Category",
    meta: CategoryD1AZXK8aTtMeta || {},
    alias: CategoryD1AZXK8aTtMeta?.alias || [],
    redirect: CategoryD1AZXK8aTtMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryD1AZXK8aTtMeta?.name ?? "package-components-Category___en",
    path: CategoryD1AZXK8aTtMeta?.path ?? "/en/package/components/Category",
    meta: CategoryD1AZXK8aTtMeta || {},
    alias: CategoryD1AZXK8aTtMeta?.alias || [],
    redirect: CategoryD1AZXK8aTtMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryD1AZXK8aTtMeta?.name ?? "package-components-Category___ru",
    path: CategoryD1AZXK8aTtMeta?.path ?? "/ru/package/components/Category",
    meta: CategoryD1AZXK8aTtMeta || {},
    alias: CategoryD1AZXK8aTtMeta?.alias || [],
    redirect: CategoryD1AZXK8aTtMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryPalletc3xu70dcmGMeta?.name ?? "package-components-CategoryPallet___kk",
    path: CategoryPalletc3xu70dcmGMeta?.path ?? "/package/components/CategoryPallet",
    meta: CategoryPalletc3xu70dcmGMeta || {},
    alias: CategoryPalletc3xu70dcmGMeta?.alias || [],
    redirect: CategoryPalletc3xu70dcmGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue").then(m => m.default || m)
  },
  {
    name: CategoryPalletc3xu70dcmGMeta?.name ?? "package-components-CategoryPallet___en",
    path: CategoryPalletc3xu70dcmGMeta?.path ?? "/en/package/components/CategoryPallet",
    meta: CategoryPalletc3xu70dcmGMeta || {},
    alias: CategoryPalletc3xu70dcmGMeta?.alias || [],
    redirect: CategoryPalletc3xu70dcmGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue").then(m => m.default || m)
  },
  {
    name: CategoryPalletc3xu70dcmGMeta?.name ?? "package-components-CategoryPallet___ru",
    path: CategoryPalletc3xu70dcmGMeta?.path ?? "/ru/package/components/CategoryPallet",
    meta: CategoryPalletc3xu70dcmGMeta || {},
    alias: CategoryPalletc3xu70dcmGMeta?.alias || [],
    redirect: CategoryPalletc3xu70dcmGMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/CategoryPallet.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockGWpUqlpKfpMeta?.name ?? "package-components-PalletBlock___kk",
    path: PalletBlockGWpUqlpKfpMeta?.path ?? "/package/components/PalletBlock",
    meta: PalletBlockGWpUqlpKfpMeta || {},
    alias: PalletBlockGWpUqlpKfpMeta?.alias || [],
    redirect: PalletBlockGWpUqlpKfpMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockGWpUqlpKfpMeta?.name ?? "package-components-PalletBlock___en",
    path: PalletBlockGWpUqlpKfpMeta?.path ?? "/en/package/components/PalletBlock",
    meta: PalletBlockGWpUqlpKfpMeta || {},
    alias: PalletBlockGWpUqlpKfpMeta?.alias || [],
    redirect: PalletBlockGWpUqlpKfpMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: PalletBlockGWpUqlpKfpMeta?.name ?? "package-components-PalletBlock___ru",
    path: PalletBlockGWpUqlpKfpMeta?.path ?? "/ru/package/components/PalletBlock",
    meta: PalletBlockGWpUqlpKfpMeta || {},
    alias: PalletBlockGWpUqlpKfpMeta?.alias || [],
    redirect: PalletBlockGWpUqlpKfpMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/PalletBlock.vue").then(m => m.default || m)
  },
  {
    name: HeadWithSearch7Oy0JowK22Meta?.name ?? "package-components-TableSearch-HeadWithSearch___kk",
    path: HeadWithSearch7Oy0JowK22Meta?.path ?? "/package/components/TableSearch/HeadWithSearch",
    meta: HeadWithSearch7Oy0JowK22Meta || {},
    alias: HeadWithSearch7Oy0JowK22Meta?.alias || [],
    redirect: HeadWithSearch7Oy0JowK22Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue").then(m => m.default || m)
  },
  {
    name: HeadWithSearch7Oy0JowK22Meta?.name ?? "package-components-TableSearch-HeadWithSearch___en",
    path: HeadWithSearch7Oy0JowK22Meta?.path ?? "/en/package/components/TableSearch/HeadWithSearch",
    meta: HeadWithSearch7Oy0JowK22Meta || {},
    alias: HeadWithSearch7Oy0JowK22Meta?.alias || [],
    redirect: HeadWithSearch7Oy0JowK22Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue").then(m => m.default || m)
  },
  {
    name: HeadWithSearch7Oy0JowK22Meta?.name ?? "package-components-TableSearch-HeadWithSearch___ru",
    path: HeadWithSearch7Oy0JowK22Meta?.path ?? "/ru/package/components/TableSearch/HeadWithSearch",
    meta: HeadWithSearch7Oy0JowK22Meta || {},
    alias: HeadWithSearch7Oy0JowK22Meta?.alias || [],
    redirect: HeadWithSearch7Oy0JowK22Meta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/HeadWithSearch.vue").then(m => m.default || m)
  },
  {
    name: TableBlockL0Zy8p1xryMeta?.name ?? "package-components-TableSearch-TableBlock___kk",
    path: TableBlockL0Zy8p1xryMeta?.path ?? "/package/components/TableSearch/TableBlock",
    meta: TableBlockL0Zy8p1xryMeta || {},
    alias: TableBlockL0Zy8p1xryMeta?.alias || [],
    redirect: TableBlockL0Zy8p1xryMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue").then(m => m.default || m)
  },
  {
    name: TableBlockL0Zy8p1xryMeta?.name ?? "package-components-TableSearch-TableBlock___en",
    path: TableBlockL0Zy8p1xryMeta?.path ?? "/en/package/components/TableSearch/TableBlock",
    meta: TableBlockL0Zy8p1xryMeta || {},
    alias: TableBlockL0Zy8p1xryMeta?.alias || [],
    redirect: TableBlockL0Zy8p1xryMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue").then(m => m.default || m)
  },
  {
    name: TableBlockL0Zy8p1xryMeta?.name ?? "package-components-TableSearch-TableBlock___ru",
    path: TableBlockL0Zy8p1xryMeta?.path ?? "/ru/package/components/TableSearch/TableBlock",
    meta: TableBlockL0Zy8p1xryMeta || {},
    alias: TableBlockL0Zy8p1xryMeta?.alias || [],
    redirect: TableBlockL0Zy8p1xryMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableBlock.vue").then(m => m.default || m)
  },
  {
    name: TableRow6hMD4YcPkeMeta?.name ?? "package-components-TableSearch-TableRow___kk",
    path: TableRow6hMD4YcPkeMeta?.path ?? "/package/components/TableSearch/TableRow",
    meta: TableRow6hMD4YcPkeMeta || {},
    alias: TableRow6hMD4YcPkeMeta?.alias || [],
    redirect: TableRow6hMD4YcPkeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue").then(m => m.default || m)
  },
  {
    name: TableRow6hMD4YcPkeMeta?.name ?? "package-components-TableSearch-TableRow___en",
    path: TableRow6hMD4YcPkeMeta?.path ?? "/en/package/components/TableSearch/TableRow",
    meta: TableRow6hMD4YcPkeMeta || {},
    alias: TableRow6hMD4YcPkeMeta?.alias || [],
    redirect: TableRow6hMD4YcPkeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue").then(m => m.default || m)
  },
  {
    name: TableRow6hMD4YcPkeMeta?.name ?? "package-components-TableSearch-TableRow___ru",
    path: TableRow6hMD4YcPkeMeta?.path ?? "/ru/package/components/TableSearch/TableRow",
    meta: TableRow6hMD4YcPkeMeta || {},
    alias: TableRow6hMD4YcPkeMeta?.alias || [],
    redirect: TableRow6hMD4YcPkeMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableRow.vue").then(m => m.default || m)
  },
  {
    name: TableSearchcsdeuJ5GaEMeta?.name ?? "package-components-TableSearch-TableSearch___kk",
    path: TableSearchcsdeuJ5GaEMeta?.path ?? "/package/components/TableSearch/TableSearch",
    meta: TableSearchcsdeuJ5GaEMeta || {},
    alias: TableSearchcsdeuJ5GaEMeta?.alias || [],
    redirect: TableSearchcsdeuJ5GaEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue").then(m => m.default || m)
  },
  {
    name: TableSearchcsdeuJ5GaEMeta?.name ?? "package-components-TableSearch-TableSearch___en",
    path: TableSearchcsdeuJ5GaEMeta?.path ?? "/en/package/components/TableSearch/TableSearch",
    meta: TableSearchcsdeuJ5GaEMeta || {},
    alias: TableSearchcsdeuJ5GaEMeta?.alias || [],
    redirect: TableSearchcsdeuJ5GaEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue").then(m => m.default || m)
  },
  {
    name: TableSearchcsdeuJ5GaEMeta?.name ?? "package-components-TableSearch-TableSearch___ru",
    path: TableSearchcsdeuJ5GaEMeta?.path ?? "/ru/package/components/TableSearch/TableSearch",
    meta: TableSearchcsdeuJ5GaEMeta || {},
    alias: TableSearchcsdeuJ5GaEMeta?.alias || [],
    redirect: TableSearchcsdeuJ5GaEMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/components/TableSearch/TableSearch.vue").then(m => m.default || m)
  },
  {
    name: index5dwxxXdK4IMeta?.name ?? "package___kk",
    path: index5dwxxXdK4IMeta?.path ?? "/package",
    meta: index5dwxxXdK4IMeta || {},
    alias: index5dwxxXdK4IMeta?.alias || [],
    redirect: index5dwxxXdK4IMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue").then(m => m.default || m)
  },
  {
    name: index5dwxxXdK4IMeta?.name ?? "package___en",
    path: index5dwxxXdK4IMeta?.path ?? "/en/package",
    meta: index5dwxxXdK4IMeta || {},
    alias: index5dwxxXdK4IMeta?.alias || [],
    redirect: index5dwxxXdK4IMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue").then(m => m.default || m)
  },
  {
    name: index5dwxxXdK4IMeta?.name ?? "package___ru",
    path: index5dwxxXdK4IMeta?.path ?? "/ru/package",
    meta: index5dwxxXdK4IMeta || {},
    alias: index5dwxxXdK4IMeta?.alias || [],
    redirect: index5dwxxXdK4IMeta?.redirect || undefined,
    component: () => import("/var/www/pecom.kz/www/frontend/pages/package/index.vue").then(m => m.default || m)
  }
]